/** @jsx jsx */
import { css, jsx, withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import DotIcon from "../../components/DotIcon";

import FolderHeader from "../../components/FolderHeader";
import { H2, H3 } from "../../components/text";
import Wrapper from "../../components/Wrapper";

import { app, faq } from "../../config";

import lineImg from "../../images/linie.png";

const AccordionAnswer = styled.div`
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  ${({ height }) => `max-height: ${height}px;`}
`;

const AccordionItem = withTheme(
  ({ theme, question, answer, open = false, onClick, id, ...props }) => {
    const [isOpen, setIsOpen] = React.useState(open);
    const [height, setHeight] = React.useState(0);
    const answerRef = React.useRef();

    React.useEffect(() => {
      if (answerRef.current) {
        setHeight(isOpen ? answerRef.current.scrollHeight : 0);
      }
    }, [isOpen]);

    const handleClick = (e) => {
      if (e) e.preventDefault();
      setIsOpen((o) => !o);
      if (typeof onClick !== "undefined") {
        onClick();
      }
    };

    const triggerId = "trigger" + id,
      answerId = "answer" + id;

    return (
      <React.Fragment>
        <H3
          id={triggerId}
          onClick={handleClick}
          aria-expanded={isOpen ? "true" : "false"}
          aria-controls={answerId}
          css={css`
            padding: 25px 45px 25px 0;
            cursor: pointer;
            ${theme.funcs.mediaquery("xl", "padding-right: 65px;")}
          `}
          {...props}
        >
          {question}
          <DotIcon
            icon={isOpen ? "arrowUp" : "arrowDown"}
            outline
            small
            color="currentColor"
            bgColor="currentColor"
            css={css`
              position: absolute;
              right: 0;
              top: 25px;

              ${theme.funcs.mediaquery("xl", "top: 23px;")}
            `}
          />
        </H3>
        <AccordionAnswer
          id={answerId}
          ref={answerRef}
          height={height}
          aria-labelledby={triggerId}
        >
          <p
            css={css`
              padding-bottom: 20px;
            `}
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        </AccordionAnswer>
      </React.Fragment>
    );
  }
);

const AppFaqPage = withTheme(({ theme, location: { pathname } }) => {
  pathname = pathname.endsWith("/")
    ? pathname.substr(0, pathname.length - 1)
    : pathname;
  const character = app.routes.find((r) => r.path === pathname);

  return (
    <main
      css={css`
        overflow: hidden;
      `}
    >
      <FolderHeader text={character.name} />
      <div
        css={css`
          position: relative;
          padding-top: ${76 + 80}px;
          padding-bottom: 320px;
          min-height: calc(100vh - 160px);
          ${theme.funcs.mediaquery(
            "xl",
            `
          padding-top: ${95 + 65}px;
      `
          )}
          ${theme.funcs.mediaquery(
            "x4l",
            `
          padding-bottom: 100px;
      `
          )}
        `}
      >
        <Wrapper
          css={css`
            position: relative;
            z-index: 1;
            padding-bottom: 60px;
          `}
        >
          <H2>So funktioniert das Meisterdetektiv-Programm</H2>
          <ul
            css={css`
              list-style: none;
              margin: 0;
              padding: 0;

              > li + li {
                &:before {
                  content: "";
                  display: block;
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 1px;
                  background: url(${lineImg}) left top no-repeat;
                  background-size: 100%;
                }
              }
            `}
          >
            {faq.map((item, i) => (
              <li
                key={i}
                css={css`
                  position: relative;
                `}
              >
                <AccordionItem id={i} question={item.q} answer={item.a} />
              </li>
            ))}
          </ul>
        </Wrapper>
        <StaticImage
          src="../../images/kloesschen.png"
          alt=""
          layout="constrained"
          width={244}
          aria-hidden="true"
          placeholder="none"
          css={css`
            position: absolute;
            right: 0;
            bottom: 0;
          `}
        />
      </div>
    </main>
  );
});

export default AppFaqPage;
